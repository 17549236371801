.about-cols {
  margin-bottom:65px;
  .col {
    .img-wrap {
      margin-top:60px;
      &:nth-child(1) {
        margin-top:0;
      }
    }
  }
}