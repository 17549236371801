.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}

.minor-inner-wrap {
  max-width:$smll-container + px;
  width:90%;
  margin:auto;
}

.row-top {
  display: flex;
  .col {
    &:nth-child(1) {
      flex-grow:1;
    }
    a.btn {
      font-size:12px;
      padding:8px 20px;
    }
    @media(max-width:760px) {
      &:nth-child(2) { display:none; }
    }
  }
}

.col-wrap {
  display: flex;
  .col {
    &:nth-child(1) {
      width:48%;
      padding-right:80px;
    }
    &:nth-child(2) {
      width:51.9230769231%;
    }
  }
  @media(max-width:960px) {
    flex-direction: column;
    .col {
      &:nth-child(1) {
        width:100%;
        padding-right:0;
        padding-bottom:45px;
      }
      &:nth-child(2) {
        width:100%;
      }
    }
  }
}

.number-wrap {
 padding:30px;
 background:#2C3430;
 @media(max-width:450px) { padding:20px; }
 @media(max-width:320px) { padding:10px; }
 .top {
   display:block;
   h4 {
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 1.75;
    margin-bottom:10px;
   }
   ul {
     margin:0 -5px;
     padding:0;
     list-style:none;
     display: flex;
     flex-wrap:wrap;
     li {
      width:calc(25% - 10px);
      margin-left:5px;
      margin-right:5px;
      color:#fff;
      text-align:center;
      padding:15px 0;
      margin-bottom:10px;
      @media(max-width:600px) {
        width:calc(50% - 10px);
      }
      @media(max-width:290px) {
        width:calc(100% - 10px);
      }
      h4 {
        font-size: 44px;
        letter-spacing: 0;
        line-height: 1;
        color:#fff;
        @media(max-width:900px) { font-size:39px; }
        @media(max-width:600px) { font-size:33px; }
        @media(max-width:450px) { font-size:29px; }
      }
      h5 {
        font-size: 13px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 1.8;
        text-transform: uppercase;
        @media(max-width:600px) { font-size:12px; }
        @media(max-width:450px) { font-size:11px; }
      }
      &:nth-child(4n+1) {
        background-color:#D1345B;
      }
      &:nth-child(4n+2) {
        background-color:#3891A6;
      }
      &:nth-child(4n+3) {
        background-color:#4C5B5C;
      }
      &:nth-child(4n+4) {
        background-color:#EFD03F;
      }
     }
     &[data-sub-items="2"] {
      li {
        width:calc(50% - 10px);
      }
     }
   }
 }
 .mid {
   text-align:center;
    p {
      font-size: 16px;
      color: rgba(#FFFFFF, 0.49);
      letter-spacing: 0;
      line-height: 1.4;
      margin:0 0 10px;
      font-family:$second-title-font-family;
      font-style: normal;
      font-weight: 400;
      @media(max-width:900px) { font-size:15px; }
      @media(max-width:600px) { font-size:14px; }
      @media(max-width:450px) { font-size:13px; }
    }
 }
 .btm {
    a.btn {
      width:100%;
      text-align:center;
    }
 }
 &.half {
   display: inline-block;
   margin-left:25px;
   width:calc(50% - 12.5px);
   vertical-align: top;
   &:nth-child(1) {
     margin-left:0;
   }
   &:nth-child(2) {
     .top {
       ul {
         li {
            &:nth-child(1) {
              background-color:#4C5B5C;
            }
            &:nth-child(2) {
              background-color:#EFD03F;
            }
         }
       }
     }
   }
 }
}



.tours-overview {
  max-width:1195px;
  margin:85px auto 0;
  @media(max-width:900px) { margin-top:65px; }
  @media(max-width:600px) { margin-top:50px; }
  @media(max-width:450px) { margin-top:40px; }
  width:90%;
  .inner-wrap {
    width:100%;
  }
  .cat-wrap {
    padding:85px 0 0;
    margin:0 0 40px;
    h3 {
      margin-bottom:7px;
    }
    h2 {
      font-size: 42px;
      color: #333333;
      letter-spacing: 0;
      line-height: 1;
      max-width:660px;
      @media(max-width:900px) { font-size: 36px }
      @media(max-width:600px) { font-size: 30px }
      @media(max-width:450px) { font-size: 26px }
    }
    .title-wrap {
      padding:0 30px;
      @media(max-width:900px) { padding:0; }
    }
  }
  .lists-wrap {
    padding:0 0 55px;
    @media(max-width:900px) { padding: 0 0 45px; }
    @media(max-width:600px) { padding: 0 0 35px; }
    @media(max-width:450px) { padding: 0 0 25px; }
    ul {
      display:flex;
      flex-wrap: wrap;
      margin:0 -35px;
      @media(max-width:600px) {
        flex-direction: column;
        margin:0;
      }
      li {
        margin:0 35px;
        width:calc(50% - 70px);
        @media(max-width:600px) {
          width:100%;
          margin:0 0 35px;
        }
        .img-wrap {

        }
        .txt-wrap {
          padding:35px 20px;
          @media(max-width:900px) { padding:25px 0; }
          h4 {
            font-size: 18px;
            color:#C7A511;
            letter-spacing: 0;
            line-height: 1.3;
            margin:0 0 10px;
          }
          p {
            font-size: 16px;
            color: #4A4A4A;
            letter-spacing: 0;
            line-height: 1.5;
            margin-bottom:30px;

          }

          .read-more-btn {
            color:#3891A6;
            font-weight:600;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &[data-number-items="1"] {
        li {
          display: flex;
          padding:80px 0 30px;
          flex-wrap: nowrap;
          width:100%;
          @media(max-width:900px) {
            flex-direction: column;
            padding:0;
            padding-top:50px;
          }
          @media(max-width:450px) {
            padding-top:35px;
          }
          .txt-wrap {
            width:calc(48%);
            padding:0;
            padding-right:90px;
            @media(max-width:900px) {
              padding-right:0px;
              width:100%;
            }
            .cat-wrap {
              padding:0;
              .title-wrap {
                margin-bottom:30px;
                padding-left:0;
              }
            }
          }
          .img-wrap {
            width:calc(51.9230769231%);
            @media(max-width:900px) {
              width:100%;
            }
          }
        }

      }
      &[data-cat="1"] {
        counter-reset: touritem;
        li {
          position: relative;
          &:before {
            counter-increment: touritem;
            content: counter(touritem);
            background:url('../imgs/badge_counter.png') no-repeat center;
            background-size:cover;
            width:93px;
            height:93px;
            color:#fff;
            text-align:center;
            line-height:90px;
            font-size: 42px;
            position: absolute;
            left:-26.5px;
            top:-26.5px;
            display: inline-block;
            z-index: 100;
            font-family:$title-font-family;
            @media(max-width:900px) {
              height:73px;
              width:73px;
              font-size:36px;
              line-height:70px;
              left:-20.5px;
              top:-20.5px;
            }
            @media(max-width:600px) {
              height:60px;
              width:60px;
              font-size:36px;
              line-height:58px;
              left:-13.5px;
              top:-13.5px;
            }
            @media(max-width:450px) {
              height:50px;
              width:50px;
              font-size:32px;
              line-height:48px;
            }
          }
          .txt-wrap {
            padding-top:15px;
            padding-bottom:75px;
            @media(max-width:900px) { padding-bottom:60px; }
            @media(max-width:600px) { padding-bottom:50px; }
            @media(max-width:450px) { padding-bottom:25px; }
          }
        }

      }
    }
  }
}

.contactbooking{
  .inner-wrap{
    padding: 65px 0 80px;
    width: 90%;
    max-width: 1070px;
    background: url(../imgs/line.png) top repeat-x;
    .title-wrap{
      margin-bottom: 40px;
    }
  }
}
