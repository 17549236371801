footer {
  background:rgba(#2C3430, 0.9);
  position: relative;
  margin-top:50px;
  &:after {
    content: " ";
    height:69px;
    width:100%;
    position: absolute;
    top:-1px;
    left:0;
    background-image:url('../imgs/footer_border_top.png');
    background-repeat: repeat-x;
    background-position: top center;
  }
  .top {
    padding:90px 0 50px;
    text-align:center;
    .title-wrap {
      .subTitle { }
      .title { color:#fff; }
    }
    .row {
      display: flex;
      max-width:760px;
      margin:50px auto 0;
      @media(max-width:820px) {
        flex-direction: column;
      }
      .column-wrap {
        width:calc(50% - 60px);
        margin-left:30px;
        margin-right:30px;
        padding:25px 35px;
        @media(max-width:820px) {
          width:calc(100% - 60px);
          &:nth-child(1) {
            margin-bottom:25px;
          }
        }
        @media(max-width:600px) {
          width:calc(100%);
          margin-left:0;
          margin-right:0;
        }
        @media(max-width:400px) {
          padding:20px 30px;
        }
        @media(max-width:350px) {
          padding:15px 20px;
        }
        border:3px dashed #979797;
        .column-title {
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 1.75;
          text-transform: uppercase;
          font-family:$third-title-font-family;
          text-align:left;
          font-weight:700;
          min-height:50px;
          @media(max-width:820px) {
            min-height:0;
          }
        }
        .figures-wrap {
          display: flex;
          flex-wrap:wrap;
          margin:12px auto;
          .col {
            background:#D1345B;
            width:calc(50% - 7.5px);
            text-align:center;
            color:#fff;
            padding:20px 0;
            font-family:$second-title-font-family;
            font-style: normal;
            font-weight: 400;
            &:nth-child(2n+1) { 
              background:#D1345B; 
              margin-right:15px;
            }
            &:nth-child(2n+2) { background:#3891A6; }
            .figures-value {
              font-size:44px;
              line-height:1;
            }
            .figures-title {
              font-size:13px;
              text-transform: uppercase;
            }
            @media(max-width:900px) {
              width:calc(50% - 7.5px);
            }
            @media(max-width:400px) {
              width:calc(100%);
              margin-right:0;
              &:nth-child(2n+1) { 
                margin-right:0;
              }
            }

          }
        }
        .column-summary {
          font-size: 16px;
          color: rgba(#FFFFFF, 0.49);
          letter-spacing: 0;
          line-height:1.5;
          font-family:$second-title-font-family;
          text-align:left;
        }
        a.btn {
          color:white;
          background:#56A2C5;
          font-size:16px;
          font-family:$second-title-font-family;
          display: block;
          padding:10px 0;
          margin-top:10px;   
        }
        &:nth-child(2) {
          .figures-wrap {
            .col {
              &:nth-child(2n+1) { background:#4C5B5C; }
              &:nth-child(2n+2) { background:#EFD03F; }
            }
          }
        }
      }
    }
  }
  .btm {
    background:#242B27;
    padding:40px 0;
    color:#898989;
    font-size:13px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6;
    .inner-wrap {
      display: flex;
      .col {
        &:nth-child(1) {
          flex-grow:1;
          a {
            color:#898989;
            text-decoration: underline;
          }
        }
        &:nth-child(2) {
          font-size:24px;
          a {
            color:#242B27;
            display: inline-block;
            width:37px;
            height:37px;
            line-height:37px;
            background:#FFFFFF;
            text-align:center;
            margin-left:10px;
            border-radius:3px;
          }
        } 
      }
      @media(max-width:650px) {
        flex-direction: column;
        text-align:center;
        .col {
          &:nth-child(1) {
            margin-bottom:15px;
          }
        }
      }
    }
  }
}
