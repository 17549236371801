#mobile-nav-items {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 374px;
    max-width:100vw;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 50;
    text-align:left;
    padding:0 30px;
    background:#000;
    box-shadow: inset 8px 0px 19px -14px rgba(48,48,48,0.28);
    .mobile-menu-header {
      padding-top:30px;
      width:214px;
      a {
        background:$yellow;
        padding:15px 25px;
        display: inline-block;
      }
      @media(max-width:600px) { padding-top:25px; }
      @media(max-width:450px) { padding-top:20px; }
    }
    ul {
      margin:25px 0;
      li {
        font-weight:900;
        text-transform: uppercase;
        display: block;
        text-align:left;
        color:#fff;
        font-family:$title-font-family;
        font-size: 15px;
        color: #6C6888;
        border-top: 1px solid #E6E6E6;
        letter-spacing: 1.33px;
        text-shadow: 0 0 6px rgba(0,0,0,0.50);
        &:nth-child(1) {
          border-top:0;
        }
        li {
          padding:5px 10px;
        }
        a,span {
          display:inline-block;
          line-height: 1.25em;
          padding:0;
          color:#fff;
          padding:15px 0;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        &.active {
          a {
            color:$yellow;
          }
        }
        &:last-child {
          padding-top:15px;
          a {
            @extend .btn;
            background:$yellow;
            text-align:center;
            width:100%;
            display: block;
            font-size:15px;
          }
          &.active {
            a { background:$yellow; color:#fff; }
          }
        }

      }
      &.second {
        display: none;
        margin-top:0;
        li {
          border-bottom:0;
          display: flex;
          padding:0 24px;
          text-transform: none;
          margin-bottom:5px;
          span {
            padding:0;
            margin-right:8px;
          }
          a {
            padding:0;
            font-size:13px;
            display: inline-block;
          }
        }
      }
      &.open {
        display: block;
      }
    }
}
