

h1, h2, h3, h4 {
  color:$black;
  text-transform: uppercase;
  font-family: $title-font-family;
  font-style: normal;
  font-weight: 400;
}

.title-wrap {
  background:url('../imgs/typography_bck.png') top left no-repeat;
  background-size:contain;
  padding-top:10px;
  .subTitle {
    font-family:$third-title-font-family;
    color:#C7A511;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1;
    margin:0;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    @media(max-width:900px) { font-size:17px; }
    @media(max-width:600px) { font-size:16px; }
    @media(max-width:450px) { font-size:15px; }
  }
  .title {
    font-size: 52px;
    letter-spacing: 0;
    margin:0;
    line-height: 1;
    font-family: $title-font-family;
    text-transform: uppercase;
    font-weight: 400;
    @media(max-width:900px) { font-size:44px; }
    @media(max-width:600px) { font-size:37px; }
    @media(max-width:450px) { font-size:32px; }
  }
  &.center {
    text-align:center;
    background-position: top center;
  }
}