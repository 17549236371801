html {
  box-sizing: border-box;
}

body {
  font:16px/1.5 $standard-font-family;
  position: relative;
  font-style: normal;
  font-weight: 400;
  color:#4A4A4A;
  background:#fffcf7;
  @media (max-width:600px) { font-size:14px; }
  @media (max-width:450px) { font-size:13px; }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }


#page-wrap {
  position: relative;
}

#page-wrap-inner {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: right 0.3s ease;
  background:#fffcf7 url('../imgs/body_bck.png');
  min-height: 100vh;
  padding-top:190px;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
  &.push {
    right:374px;
    header.page-header {
      right:374px;
    }
  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.hr {
  width:100%;
  height:2px;
  margin:0 auto;
  background:url('../imgs/line.png') repeat-x;
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}

.loader {
    display: none;
}

.general-txt {
  letter-spacing: 0.38px;
  font-style: normal;
  font-weight: 400;
  h2, h3 {

  }
  p { 
    margin:1.5em 0 0;
  }
  ul {
    margin:1.2em 18px;
    list-style:disc;
    li {
      margin:7px 0;
      font-style: normal;
      font-weight: 500;
      a {
        text-decoration: underline;
        color:#646A87;
      }
    }
  }
  a {
    color:#20CADE;
  }
}


.v-wrapper {
  display:flex;
  align-items:center;
  height:100%;
  div {
    width:100%;
  }
}



.thorn-edges-wrap {
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    content: " ";
    background:url('../imgs/torn_edges.png');
    background-size:cover;
  }
  img {
    width:100%;
  }
}